// TODO generate this config at build time by reading values from from env variables or buildspec
// calling out to the orsnn-app-api-devo stack to get the values
const config = {
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://dbyhdw82a4.execute-api.us-west-2.amazonaws.com/prod/graphql',
  },
  logs: {
    REGION: 'us-west-2',
    URL: 'https://dbyhdw82a4.execute-api.us-west-2.amazonaws.com/prod/log',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_BONrnm77f',
    APP_CLIENT_ID: '1euvudkn1pfbm968bvq8b3ngec',
    IDENTITY_POOL_ID: 'us-west-2:3b13fadc-0865-4d27-84ac-6d2f065f4357',
  },
  fileGateway: {
    REGION: 'us-west-2',
    URL: 'https://dbyhdw82a4.execute-api.us-west-2.amazonaws.com/prod/files',
  },
  smartHeaderGateway: {
    REGION: 'us-west-2',
    URL: 'https://dbyhdw82a4.execute-api.us-west-2.amazonaws.com/prod/smart_header_mapping',
  },
  website: {
    URL: 'https://devo.orsnn.com',
  },
  pinpoint: {
    REGION: 'us-west-2',
    APP_ID: '36ade3756bc949f38efb32d18646487c',
  },
};

export default config;
